import './components/accordion';
import './components/lightbox';
import './components/navigation';
import './components/partner';
import 'bootstrap/js/src/carousel';

if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR');
    });
}

const csrfPlaceholderInputs = Array.from(document.querySelectorAll('[data-js-csrf]'));
csrfPlaceholderInputs.forEach(input => {
    input.setAttribute('name', window.csrfTokenName);
    input.setAttribute('value', window.csrfTokenValue);
});
