const accordionLabels = document.querySelectorAll('.accordion__button');

accordionLabels.forEach(accordionLabel => {
    accordionLabel.addEventListener('click', () => {
        const accordionBody = document.getElementById(accordionLabel.getAttribute('aria-controls'));
        const isExpanded = accordionLabel.getAttribute('aria-expanded') === 'true';

        if (isExpanded) {
            accordionLabel.setAttribute('aria-expanded', 'false');
            accordionBody.hidden = true;
        } else {
            accordionLabel.setAttribute('aria-expanded', 'true');
            accordionBody.hidden = false;
        }
    });
});
