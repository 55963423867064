const navigation = document.getElementById('site-navigation');
const toggleButton = document.getElementById('site-navigation-button');

const navigationActiveClassName = 'navigation--active';
const toggleButtonActiveClassName = 'hamburger--active';

if (toggleButton) {
    toggleButton.addEventListener('click', e => {
        const isActive = toggleButton.getAttribute('aria-expanded') === 'true';
        if (isActive) {
            navigation.classList.remove(navigationActiveClassName);
            toggleButton.classList.remove(toggleButtonActiveClassName);
            toggleButton.setAttribute('aria-expanded', 'false');
        } else {
            navigation.classList.add(navigationActiveClassName);
            toggleButton.classList.add(toggleButtonActiveClassName);
            toggleButton.setAttribute('aria-expanded', 'true');
        }
    });
}

const menuItemsWithFlyouts = Array.from(document.querySelectorAll('.menu__item--has-descendants'));
const menuItemActiveClassName = 'menu__item--active';

menuItemsWithFlyouts.forEach(item => {
    const node = item.querySelector('.menu__node');

    node.addEventListener('touchstart', e => {
        const isActive = item.classList.contains(menuItemActiveClassName);
        if (isActive) return;

        item.classList.add(menuItemActiveClassName);
        e.preventDefault();

        // Only allow one open flyout in the desktop layout.
        const isMobileLayout = Boolean(toggleButton.offsetParent);
        if (isMobileLayout) return;
        menuItemsWithFlyouts
            .filter(el => !el.isSameNode(item))
            .forEach(el => el.classList.remove(menuItemActiveClassName));
    });
});

// Close all open flyouts on any touch outside the navigation.
document.body.addEventListener('touchstart', e => {
    if (navigation.contains(e.target)) {
        return;
    }
    menuItemsWithFlyouts.forEach(el => el.classList.remove(menuItemActiveClassName));
});
