import Tobii from '@midzer/tobii';

const selector = '.lightbox';
if (document.querySelector(selector)) {
    const tobii = new Tobii({
        selector,
        captions: false,
        navLabel: ['Zum vorherigen Bild', 'Zum nächsten Bild'],
        closeLabel: 'Vergrößerungsansicht schließen',
        loadingIndicatorLabel: 'Bild wird geladen …',
    });
}
